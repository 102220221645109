<template>
  <Card :no-shadow="true">
    <template v-slot:content>
      <b-row>
        <div style="width: 82px;"> <!-- 64px + el margen -->
          <div class="icon-wrapper round background-primary">
            <b-icon :icon="icon" scale="2.5" variant="white" />
          </div>
        </div>
        <div class="pt-2 pr-3 w-75" style="flex: 1;">
          <span class="value" v-if="type === 'number'">{{ value }}</span>
          <b-progress class="mb-1 mt-1" :value="value" :max="max" v-else-if="type === 'progress'"></b-progress>

          <span class="label">{{ label }}</span>
        </div>
      </b-row>
    </template>
  </Card>
</template>

<script>
export default {
  props: {
    icon: { type: String, required: true },
    type: { type: String, required: false, default: 'number' },
    value: {
      required: true
    },
    max: {
      type: Number, required: false, default: 100
    },
    label: {
      required: true
    }
  }

}
</script>

<style lang="scss" scoped>
  .icon-wrapper {
    width: 64px;
    height: 64px;
    line-height: 64px;
    text-align: center;
  }

  .value {
    display: block;
    font-weight: 500;
  }
  .label {
    font-weight: 500;
    color: #99abb4;
  }

</style>
