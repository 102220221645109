<template>
  <Card :class="{ completed: isComplete }" no-shadow>

    <template v-slot:title>
      <div class="mb-2" style="justify-content: space-between; display: flex; align-items: flex-start;">
          <h4 style="flex: 1;">{{ machine.name }}</h4>
          <DifficultyPill :difficulty="machine.difficulty" />
          <Pill v-if="hasOS" class="ml-2" :text="machine.os" :cssClasses="{ secondary: true }" />
          <StatusPill class="ml-2" :status="machine.status" />

          <Pill class="ml-2" v-if="isMachineBlockedByOther" :cssClasses="{ error : true }" v-b-tooltip="$t('LAB_MACHINE.locked_by_other')">
            <b-icon icon="lock" />
          </Pill>
      </div>
    </template>

    <template v-slot:content>

      <div>
        <span class="font-weight-bold">{{ $t('TERMS.objective') }}</span><br>
        <p class="paragraph" v-html="translatedDescription"></p>
      </div>

      <b-row v-if="machine.instanceIp">
        <b-col>
          <span class="font-weight-bold">IP</span><br>
          <span>{{ machine.instanceIp }}</span>
        </b-col>
      </b-row>

      <div style="clear: both"></div>
      <div class="mt-2">
        <span class="font-weight-bold">{{ $t('GENERAL.actions') }}</span>
        <div class="inline-wrapper">
          <b-button
            class="mr-2"
            :to="{ name: 'machineDetail', params: { idMachine: machine.id } }"
            v-b-tooltip.hover.bottom="$t('ACTIONS.see_details')"
          >
            <b-icon icon="eye" variant="white" />
          </b-button>
          <RevertLabMachineModal only-icon v-if="canRevertMachine" @success="machineRevertSuccess" @error="machineRevertError" :lab-id="labId" :machine-id="machine.id" class="mr-2" />
          <LockLabMachine only-icon v-if="canStartMachine && isVMWareMachine" @success="machineStartSuccess" @error="machineStartError" :lab-id="labId" :machine-id="machine.id" class="mr-2" />
          <UnlockLabMachine only-icon no-event v-if="canStopMachine && isVMWareMachine" @success="machineStopSuccess" @error="machineStopError" :lab-id="labId" :machine-id="machine.id" class="mr-2" />
          <StartLabMachine only-icon v-if="canStartMachine && isAwsMachine" @success="machineStartSuccess" @error="machineStartError" :lab-id="labId" :machine-id="machine.id" class="mr-2" />
          <StopLabMachineModal only-icon v-if="canStopMachine && isAwsMachine" @success="machineStopSuccess" @error="machineStopError" :lab-id="labId" :machine-id="machine.id" class="mr-2" />
          <InsertFlagModal only-icon :lab-id="labId" @success="insertFlagSuccess" :machine-id="machine.id" v-if="canInsertFlag" class="mr-2" />
        </div>

      </div>
    </template>
  </Card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { LAB_MACHINE_TYPE, PILL_STATUS } from '@/config/constants'

import DifficultyPill from '@/components/utils/pills/DifficultyPill'
import Pill from '@/components/utils/pills/Pill'
import StatusPill from '@/components/utils/pills/StatusPill'
import InsertFlagModal from '@/components/labs/InsertFlag'
import RevertLabMachineModal from '@/components/labs/RevertLabMachine'
import StopLabMachineModal from '@/components/labs/StopLabMachine'
import StartLabMachine from '@/components/labs/StartLabMachine'
import LockLabMachine from '@/components/labs/LockLabMachine'
import UnlockLabMachine from '@/components/labs/UnlockLabMachine'

import ToastsMixin from '@/services/mixins/toasts.mixins'
import i18nmixin from '@/services/mixins/i18n.mixins'

export default {
  name: 'lab-machine',
  mixins: [ToastsMixin, i18nmixin],
  components: { DifficultyPill, StatusPill, InsertFlagModal, RevertLabMachineModal, StopLabMachineModal, StartLabMachine, Pill, LockLabMachine, UnlockLabMachine },
  props: {
    labId: { required: true },
    machine: { type: Object, required: true, default: () => {} }
  },
  methods: {
    ...mapActions({
      getLabDetail: 'labs/getLabDetail'
    }),
    getLab () {
      return this.getLabDetail(this.labId)
    },
    machineRevertSuccess () {
      return this.getLab()
        .then(_ => { this.showSuccessToast(this.$t('LAB_MACHINE.ACTIONS.revert_success')) })
        .catch(_ => { this.showErrorToast(this.$t('LAB_MACHINE.ERRORS.revert')) })
    },
    machineRevertError () {
      this.showErrorToast(this.$t('LAB_MACHINE.ERRORS.revert'))
    },
    machineStartSuccess () {
      return this.getLab()
        .then(_ => { this.showSuccessToast(this.$t('LAB_MACHINE.ACTIONS.start_machine_success')) })
        .catch(_ => { this.showErrorToast(this.$t('LAB_MACHINE.ERRORS.start')) })
    },
    machineStartError () {
      this.showErrorToast(this.$t('LAB_MACHINE.ERRORS.start'))
    },
    machineStopSuccess () {
      return this.getLab()
        .then(_ => { this.showSuccessToast(this.$t('LAB_MACHINE.ACTIONS.stop_success')) })
        .catch(_ => { this.showErrorToast(this.$t('LAB_MACHINE.ERRORS.stop')) })
    },
    machineStopError () {
      this.showErrorToast(this.$t('LAB_MACHINE.ERRORS.stop'))
    },
    insertFlagSuccess () {
      return this.getLab()
        .catch(_ => this.showErrorToast(this.$t('LAB_MACHINE.ERRORS.general')))
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    }),
    assignedToCurrentUser () { return this.machine.user === this.currentUser.username },
    hasOS () { return typeof this.machine.os !== 'undefined' && this.machine.os !== null && this.machine.os !== '' },
    isComplete () { return this.machine.status === PILL_STATUS.COMPLETE },
    isInProgress () { return this.machine.status === PILL_STATUS.IN_PROGRESS },
    isPending () { return this.machine.status === PILL_STATUS.PENDING },
    isMachineOn () { return !!this.machine.instanceIp },

    machineType () { return typeof this.machine.source !== 'undefined' ? this.machine.source : LAB_MACHINE_TYPE.AWS },
    isAwsMachine () { return this.machineType === LAB_MACHINE_TYPE.AWS },
    isVMWareMachine () { return this.machineType === LAB_MACHINE_TYPE.VMWARE },

    translatedDescription () { return this.getTranslatedValue(this.machine, 'description') },

    isMachineBlocked () { return typeof this.machine.isBlocked !== 'undefined' ? this.machine.isBlocked : false },
    isMachineBlockedByMe () { return typeof this.machine.isBlockedByYou !== 'undefined' ? this.machine.isBlockedByYou : false },
    isMachineBlockedByOther () { return this.isMachineBlocked && !this.isMachineBlockedByMe },
    isOtherMachineBlockedByMe () { return typeof this.machine.hasOtherMachineBlocked !== 'undefined' ? this.machine.hasOtherMachineBlocked : false },

    // Machine actions conditions
    // Start
    canStartMachine () { return this.isAwsMachine ? this.canStartAwsMachine : this.canStartVMWareMachine },
    canStartAwsMachine () { return !this.isMachineOn },
    canStartVMWareMachine () { return !this.isOtherMachineBlockedByMe && !this.isMachineBlocked },

    // Revert
    canRevertMachine () { return (this.isVMWareMachine && this.canRevertVMWareMachine) || (this.isAwsMachine && this.canRevertAwsMachine) },
    canRevertVMWareMachine () { return this.isMachineBlockedByMe },
    canRevertAwsMachine () { return !this.isPending && this.isMachineOn },

    // Insert flag
    canInsertFlag () { return (this.isVMWareMachine && this.canInsertFlagVMWareMachine) || (this.isAwsMachine && this.canInsertFlagAwsMachine) },
    canInsertFlagAwsMachine () { return this.isInProgress && this.isMachineOn },
    canInsertFlagVMWareMachine () { return !this.isComplete }, // VMWare machines can insert flag at all times, except when they are finished

    // Stop machine
    canStopMachine () { return (this.isAwsMachine && this.canStopAwsMachine) || (this.isVMWareMachine && this.canStopVMWareMachine) },
    canStopAwsMachine () { return !this.isPending && this.isMachineOn },
    canStopVMWareMachine () { return this.isMachineBlockedByMe }
  }
}
</script>

<style lang="scss">
.tooltip {
  white-space: pre-wrap;
}

.completed {
  border-left: 3px solid $green-dark !important;
}
</style>
