<template>
  <div class="home">
    <loading :loading="isLoading"></loading>
    <SectionHeader
    :name="translatedLab.name"
    :description="translatedLab.description"
    :loading="isLoading"
    has-back-button
    >
    </SectionHeader>

    <!-- SKELETONS LOADING -->
    <b-row class="main-container py-5 px-md-5" v-if="isLoading">
      <b-col class="mb-3" sm="12" xl="4">
        <LabStatSkeleton />
      </b-col>
      <b-col class="mb-3" sm="12" md="6" xl="4" v-for="n in Array(2)" v-bind:key="n">
        <LabStatSkeleton />
      </b-col>
      <b-col class="mb-3"
        sm="12"
        md="6"
        xl="4"
        v-for="n in Array(12)" v-bind:key="n">
        <LabMachineSkeleton />
      </b-col>
    </b-row>
    <!-- END SKELETONS LOADING -->

    <div class="main-container py-5 px-md-5" v-show="!isLoading" v-if="totalLabs > 0">
      <b-row>
        <!-- <b-col sm="12" class="mb-3">
          <VpnInfo :lab-id="labDetail.id" />
        </b-col> -->
        <b-col
        sm="12"
        xl="4"
        class="mb-3"
        >
          <StatCard
          icon="percent"
          :value="completionPercent"
          :max="1"
          type="progress"
          :label="$t('LABS.lab_complete_progress')"
         >
          </StatCard>
        </b-col>

        <b-col
        sm="12"
        md="6"
        xl="4"
        class="mb-3"
        >
          <StatCard
          icon="globe"
          :value="totalLabs"
          :label="$t('TERMS.machines')"
        >
          </StatCard>
        </b-col>

        <b-col
        sm="12"
        md="6"
        xl="4"
        class="mb-3"
        >
          <StatCard
          icon="tv-fill"
          :value="pendingLabs"
          :label="$t('TERMS.pending_machines')"
         >
          </StatCard>
        </b-col>

        <b-col cols="12">
          <hr class="mt-1 mb-4">
        </b-col>

        <b-col
        sm="12"
        md="6"
        xl="4"
        v-for="machine in translatedMachines"
        v-bind:key="machine.id"
        class="mb-3"
        >
          <LabMachine :lab-id="labId" :machine="machine"></LabMachine>
        </b-col>
      </b-row>
    </div>

    <NoResults v-if="!isLoading && totalLabs === 0"/>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LabMachine from '@/components/LabMachine'
import SectionHeader from '@/components/SectionHeader'
import StatCard from '@/components/utils/cards/StatCard'
import NoResults from '@/components/utils/NoResults'
// import VpnInfo from '@/components/utils/VpnInfo'

import LabStatSkeleton from '@/components/skeletons/LabStatSkeleton'
import LabMachineSkeleton from '@/components/skeletons/LabMachineSkeleton'

import i18nmixin from '@/services/mixins/i18n.mixins'

import { LAB_MACHINE_STATUS } from '@/config/constants'

export default {
  name: 'LabDetail',
  mixins: [i18nmixin],
  components: { LabMachine, SectionHeader, StatCard, NoResults, LabStatSkeleton, LabMachineSkeleton },
  data () {
    return { }
  },
  methods: {
    ...mapActions({
      getLabDetail: 'labs/getLabDetail'
    })
  },
  computed: {
    ...mapGetters({
      labDetail: 'labs/getLabDetail',
      canSeeUserConsole: 'config/canSeeUserConsole',
      isLoading: 'labs/isLoading'
    }),
    totalLabs () { return this.machines.length },
    machines () { return typeof this.labDetail.vms !== 'undefined' ? this.labDetail.vms : [] },
    translatedMachines () { return this.translateObjectArray(this.machines) },
    pendingLabs () {
      return (this.machines || []).reduce(function (acc, item) {
        acc = item.status !== LAB_MACHINE_STATUS.COMPLETE ? (acc + 1) : acc
        return acc
      }, 0)
    },
    completionPercent () { return (this.totalLabs - this.pendingLabs) / this.totalLabs },
    labId () { return this.labDetail.id },
    hasGuacamole () { return this.canSeeUserConsole },
    translatedLab () { return this.translateObject(typeof this.labDetail !== 'undefined' ? this.labDetail : {}) }
  },
  mounted () {
    this.getLabDetail(this.$route.params.id)

    this.$root.$on('machineStoppedOrUnlocked', (data) => {
      this.getLabDetail(this.$route.params.id)
    })
  },

  beforeDestroy () {
    this.$root.$off('machineStoppedOrUnlocked')
  }
}
</script>
