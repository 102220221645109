<template>
  <b-card no-body img-top>
    <b-card-body>
      <div class="flex justify-between align-center" style="">
          <b-skeleton width="40%" height="24px"></b-skeleton>
          <b-skeleton width="30%" height="0"></b-skeleton>

          <b-skeleton width="15%" class="mr-2"></b-skeleton>
          <b-skeleton width="15%"></b-skeleton>
      </div>

      <!-- Objective -->
      <b-skeleton width="30%" class="mt-4"></b-skeleton>
      <b-skeleton width="100%" class="mt-3"></b-skeleton>
      <b-skeleton width="80%"></b-skeleton>

      <!-- Hints -->
      <b-skeleton width="30%" class="mt-3"></b-skeleton>
      <b-skeleton width="70%" class="mt-1"></b-skeleton>
      <b-skeleton width="40%" class="mt-1"></b-skeleton>
      <b-skeleton width="90%" class="mt-1"></b-skeleton>

      <!-- IP -->
      <b-skeleton width="8%" class="mt-3"></b-skeleton>
      <b-skeleton width="15%" class="mt-1"></b-skeleton>

      <b-skeleton width="30%" class="mt-3"></b-skeleton>
      <b-skeleton width="100%" type="button" class="mt-2"></b-skeleton>
      <b-skeleton width="100%" type="button" class="mt-1"></b-skeleton>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  name: 'LabMachineSkeleton'
}
</script>
