<template>
  <b-card>
    <div class="flex" style="display: flex;">
      <b-skeleton type="avatar" class="mr-3" width="64px" height="64px"></b-skeleton>
      <div style="flex: 1;">
        <b-skeleton width="100%"></b-skeleton>
        <b-skeleton width="100%"></b-skeleton>
      </div>
    </div>
  </b-card>
</template>

<script>
export default {
  name: 'LabStatSkeleton'
}
</script>
